<template>
  <div class="wrapper">
    <row class="row ff-flama">
      <column
        md="12"
        lg="2">
        <h2 class="ff-flama fs-xl fw-light tt-uppercase mb-5">
          Regions
        </h2>
      </column>

      <column
        xs="12"
        sm="6"
        md="2">
        <ul class="unstyled-list fs-md">
          <li class="fw-bold fs-lg td-underline mb-0">
            <a href="/tag/africa">Africa</a>
          </li>
          <li class="mb-0 td-underline">
            <a href="/tag/north_africa">North Africa</a>
          </li>
          <li class="td-underline mb-3">
            <a href="/tag/sub_saharan_africa">Sub-Saharan Africa</a>
          </li>
        </ul>
      </column>

      <column
        xs="12"
        sm="6"
        md="2">
        <ul class="unstyled-list">
          <li class="fw-bold fs-lg mb-0 td-underline">
            <a href="/tag/americas">Americas</a>
          </li>
          <li class="mb-0 td-underline">
            <a href="/tag/north_america">North America</a>
          </li>
          <li class="mb-0 td-underline">
            <a href="/tag/central_america_and_caribbean">
              Central America<br>
              &amp; Caribbean
            </a>
          </li>
          <li class="td-underline mb-3">
            <a href="/tag/south_america">South America</a>
          </li>
        </ul>
      </column>

      <column
        xs="12"
        sm="6"
        md="3"
        lg="2">
        <ul class="unstyled-list fs-md">
          <li class="fw-bold fs-lg mb-0 td-underline">
            <a href="/tag/asia_and_oceania">Asia &amp; Oceania</a>
          </li>
          <li class="mb-0 td-underline">
            <a href="/tag/east_asia">East Asia</a>
          </li>
          <li class="mb-0 td-underline">
            <a href="/tag/central_asia">Central Asia</a>
          </li>
          <li class="mb-0 td-underline">
            <a href="/tag/south_asia">South Asia</a>
          </li>
          <li class="mb-0 td-underline">
            <a href="/tag/southeast_asia">Southeast Asia</a>
          </li>
          <li class="td-underline mb-3">
            <a href="/tag/oceania">Oceania</a>
          </li>
        </ul>
      </column>

      <column
        xs="12"
        sm="6"
        md="2">
        <ul class="unstyled-list fs-md">
          <li class="fw-bold fs-lg mb-0 td-underline">
            <a href="/tag/europe">Europe</a>
          </li>
          <li class="mb-0 td-underline">
            <a href="/tag/western_europe">Western Europe</a>
          </li>
          <li class="mb-0 td-underline">
            <a href="/tag/southern_europe">Southern Europe</a>
          </li>
          <li class="td-underline mb-3">
            <a href="/tag/eastern_europe">Eastern Europe</a>
          </li>
        </ul>
      </column>

      <column
        xs="12"
        sm="6"
        md="2">
        <ul class="unstyled-list fs-md">
          <li class="fw-bold fs-lg mb-0 td-underline">
            <a href="/tag/middle_east">Middle East</a>
          </li>
          <li class="mb-3 td-underline">
            <a href="/tag/middle_east">Middle East</a>
          </li>
        </ul>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'regions-page',
  extends: Page
};
</script>
